import { getService } from 'react-in-angularjs';
import { enqueueSnackbar } from 'notistack';

import { getFormattedDate } from '../../../../../../../shared/utils/dateUtils';
import {
  dateFormat,
  dateTimeFormat,
} from '../../../../../../../shared/constants/apiDateFormat';

export const openScanSummary = async (
  selectedScanTime: string | undefined,
  customerId: number,
) => {
  const ScenariosService = getService('ScenariosService');
  const ModalService = getService('ModalService');

  const scanDate = selectedScanTime
    ? getFormattedDate(selectedScanTime, dateFormat, dateTimeFormat)
    : undefined;

  const resp = await ScenariosService.getSuggestionForUser(customerId, scanDate);

  if (resp.status === 200) {
    ModalService.suggestScenario(resp.data);
  } else {
    enqueueSnackbar(resp.data.message, {
      variant: 'error',
    });
  }
};
