import React, { useEffect, useState } from 'react';

import { Button } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

import classes from './RefreshButton.module.scss';
import { chatService } from '../../../services/chatService';

const RefreshButton = () => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    refreshUsers();
  }, []);

  const refreshUsers = async () => {
    setIsLoading(true);
    try {
      await chatService.refreshUsers();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button
      className={classes.button}
      onClick={refreshUsers}
      loading={isLoading}
      loadingPosition="start"
      startIcon={<RefreshIcon />}
      variant="contained"
    >
      Refresh
    </Button>
  );
};

export { RefreshButton };
