import React from 'react';

import { Paper } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { Dictionary } from 'lodash';

import classes from './MealScheduleForm.module.scss';
import { MealTimesTable } from '../../../../../components/MealTimesTable/MealTimesTable';
import {
  REGULAR_MEAL_NAME,
  WORKOUT_MEAL_NAME,
} from '../../../../../../shared/constants/mealNames';
import { getEnumArray } from '../../../../../../shared/utils/jsUtils';
import { LbCheckbox } from '../../../../../../shared/components/form-components';
import { TitleWithDescription } from '../../../../../../shared/components/text-formation-components';
import { MealSchedule } from '../../../../../interfaces/MealTimes';
import { InfoBox } from '../../../../../../shared/components/ui-components';
import { Mode, Option } from '../../../../../../general-types';
import { MealPlan } from '../../../../../interfaces/MealPlan';
import { ReadOnlyWorkoutMealDay } from './ReadOnlyWorkoutMealDay/ReadOnlyWorkoutMealDay';

const mealNames = getEnumArray(REGULAR_MEAL_NAME);
const workoutMealNames = getEnumArray(WORKOUT_MEAL_NAME);

type MealScheduleFormProps = {
  mode: Mode;
  mealPlans: Dictionary<MealPlan>;
  mealPlansOptions: Option[];
};

const MealScheduleForm = ({
  mode,
  mealPlans,
  mealPlansOptions,
}: MealScheduleFormProps) => {
  const { control, watch } = useFormContext<MealSchedule>();

  return (
    <form noValidate>
      <Paper variant="roundedBox" className={classes.mealTable}>
        <MealTimesTable
          mode={mode}
          mealNames={mealNames}
          mealFormKey="mealTime"
          mealTitle="Meals"
          renderDayTitle={(day) => <p>{day}</p>}
          mealPlansOptions={mealPlansOptions}
          getIsDisabledTime={({ day, mealName }) => {
            const mealPlanId = watch(`${day}.mealPlanOrigin`);
            const isInMealPlanOnlyTwoSnacks = mealPlans[mealPlanId]?.mealPlan === 5;
            const isSnack1 = mealName === 'snack1';

            return isInMealPlanOnlyTwoSnacks && isSnack1;
          }}
        />
      </Paper>
      <InfoBox
        title={
          <TitleWithDescription
            title="Specify workout days"
            description="Turn on days when the user has a workout."
          />
        }
        customContentPadding
      >
        <MealTimesTable
          mode={mode}
          mealNames={workoutMealNames}
          mealFormKey="workoutMealTime"
          mealTitle="Pre/Post Meals"
          renderDayTitle={(day) =>
            mode === 'EDIT' ? (
              <LbCheckbox
                label={day}
                color="primary"
                control={control}
                name={`${day}.workoutMealTime.enabled`}
                font="inherit"
              />
            ) : (
              <ReadOnlyWorkoutMealDay day={day} />
            )
          }
          getIsDisabledTime={({ day }) => {
            return !watch(`${day}.workoutMealTime.enabled`);
          }}
        />
      </InfoBox>
    </form>
  );
};

export { MealScheduleForm };
