import { InbodyIndicatorInfo } from '../../../../../../../interfaces/inbodyIndicator';
import { getScanDates } from '../../../utils/getScanDates';
import { ScanRowInfo } from '../InbodyIndicatorsTableView.config';

export const getScansInfo = (
  customerInbodyIndicators: InbodyIndicatorInfo[],
  selectedScanTime: string | null,
): ScanRowInfo[] => {
  const showScansDates = selectedScanTime
    ? [selectedScanTime]
    : getScanDates(customerInbodyIndicators);

  return showScansDates.map((scanDate) => {
    const indicatorValues: Record<string, number | null> = {};

    customerInbodyIndicators.forEach((indicator) => {
      const scan = indicator.scans.find((s) => s.scanDateTime === scanDate);
      indicatorValues[indicator.key] = scan ? scan.value : null;
    });

    return {
      scanDateTime: scanDate,
      indicatorValues,
    };
  });
};
