import React from 'react';

import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';

import classes from './ReadOnlyWorkoutMealDay.module.scss';
import { Day } from '../../../../../../../shared/constants/daysOfWeek';
import { MealSchedule } from '../../../../../../interfaces/MealTimes';

type ReadOnlyWorkoutMealDayProps = {
  day: Day;
};

const ReadOnlyWorkoutMealDay = ({ day }: ReadOnlyWorkoutMealDayProps) => {
  const { watch } = useFormContext<MealSchedule>();

  const isSelected = watch(`${day}.workoutMealTime.enabled`);

  const dayClasses = classNames({
    [classes.unselected]: !isSelected,
  });

  return <div className={dayClasses}>{day}</div>;
};

export { ReadOnlyWorkoutMealDay };
