import { enqueueSnackbar } from "notistack";

(function () {
    'use strict';

    angular.module('app.coachProfile').controller('CoachProfileController', CoachProfileController);

    CoachProfileController.$inject = [
        '$scope',
        '$location',
        'utilService',
        'CoachService',
        '$window',
        'PaidModulesUtil',
        'constants',
        'BaseUserUtils',
    ];

    function CoachProfileController($scope, $location, utilService, CoachService, $window, PaidModulesUtil, constants, BaseUserUtils) {
        var vm = this;

        vm.loggedInUser = utilService.getLoggedInUserData();
        vm.patterns = constants.enums.patterns;
        vm.coachAccountSettings = {};
        vm.coachPassword = {};
        vm.isActiveCalendarEnabled = false;
        vm.spinner = { active: false };

        vm.goToMoodleAccount = goToMoodleAccount;
        vm.getCoachAccountSettings = getCoachAccountSettings;
        vm.initTabName = initTabName;
        vm.changeTab = changeTab;
        vm.changeAvatar = changeAvatar;
        vm.setEnabledModules = setEnabledModules;
        vm.updateCoachAccountSettings = updateCoachAccountSettings;
        vm.updateCoachBio = updateCoachBio;


        vm.$onInit =() => {
            vm.initTabName();
            vm.getCoachAccountSettings();
            vm.setEnabledModules();
        }

        function changeAvatar(newAvatar) {
            BaseUserUtils.updateAvatar(newAvatar, vm.coachAccountSettings);
        }

        function goToMoodleAccount(path) {
            $window.open(path, '_blank');
        }

        function initTabName() {
            vm.tabName = $location.url() === '/coach-profile/notes' ? 'notes' : 'accountSettings';
        }

        function getCoachAccountSettings() {
            vm.spinner.active = true;
            CoachService.getCoachAccountSettings(vm.loggedInUser.id).then(function (res) {
                if (res.status === 200) {
                    vm.coachAccountSettings = res.data;
                }
            }).finally(() => {
                vm.spinner.active = false;
            });
        }

        function updateCoachAccountSettings(data) {
            vm.spinner.active = true;
            CoachService.updateCoachAccountSettings(vm.loggedInUser.id, data).then(function (res) {
                if (res.status === 200) {
                    vm.loggedInUser = res.data; 
                    vm.coachAccountSettings = angular.copy(res.data);
                    enqueueSnackbar('Coach info updated successfully', { variant: 'success' });
                    utilService.applyAsyncFunc($scope);
                }
            }).finally(() => {
                vm.spinner.active = false;
            });
        }

        function updateCoachBio(bio) {
            vm.coachAccountSettings = angular.copy({
                ...vm.coachAccountSettings,
                additional: {
                    ...vm.coachAccountSettings.additional,
                   ...bio,
                },
            });

            utilService.applyAsyncFunc($scope);
        }

        function changeTab(tabName) {
            vm.tabName = tabName;
            if (vm.tabName === 'password') {
                vm.coachPassword = {};
            }
        }

        function setEnabledModules () {
            vm.isActiveCalendarEnabled = PaidModulesUtil.getIsActiveCalendarEnabled();
        }
    }
})();
