import React from 'react';

import { OptionsMenu } from '../../../../../../../shared/components/menu-components';
import { MenuOptions } from '../../../../../../../general-types';
import { Flags } from '../../../../../../../utils-types';
import { InbodyType } from '../../../../../../../shared/constants/inbody-info/InbodyType';

type InbodyIndicatorsOptionsProps = {
  onCompareScanMetrics: () => void;
  onRequestSocialTemplate: () => void;
  customerInbodyTypeFlags: Flags<InbodyType>;
};

const InbodyIndicatorsOptions = ({
  onCompareScanMetrics,
  onRequestSocialTemplate,
  customerInbodyTypeFlags,
}: InbodyIndicatorsOptionsProps) => {
  const handlePrintReport = () => {
    window.document.body.setAttribute('style', 'width:285mm');
    setTimeout(() => {
      window.print();
      window.document.body.setAttribute('style', 'width:inherit');
    }, 200);
  };

  const menuOptions: MenuOptions[] = [
    { id: 1, label: 'Compare Scan Metrics', onClick: onCompareScanMetrics },
    {
      id: 2,
      label: 'Create Social Post',
      onClick: onRequestSocialTemplate,
      isHide: customerInbodyTypeFlags.NON_INBODY,
    },
    { id: 3, label: 'Print Reports', onClick: handlePrintReport },
  ];

  return <OptionsMenu options={menuOptions} size="small" />;
};

export { InbodyIndicatorsOptions };
