import React from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import { LbBigModal } from '../../../../../../shared/components/modals';
import { CompareScanTable } from './CompareScanTable/CompareScanTable';
import { CompareDateForm } from './CompareDateForm/CompareDateForm';
import { InbodyIndicatorInfo } from '../../../../../interfaces/inbodyIndicator';
import { CompareScanMetricsFormValues } from './CompareScanMetricsModal.settings';
import { getScanDates } from '../utils/getScanDates';

type CustomerInfo = {
  gender: string;
  age: number;
};

type CompareScanMetricsModalProps = {
  customerInfo: CustomerInfo;
  customerInbodyIndicators: InbodyIndicatorInfo[];
  onClose: () => void;
};

const CompareScanMetricsModal = ({
  customerInfo,
  customerInbodyIndicators,
  onClose,
}: CompareScanMetricsModalProps) => {
  const formMethods = useForm<CompareScanMetricsFormValues>();

  const { gender, age } = customerInfo;
  const scanDateTimes = getScanDates(customerInbodyIndicators);
  return (
    <LbBigModal
      open
      title="Compare Scan Metrics"
      description={`${gender}, ${age} years`}
      buttonOptions={{ yesVisible: false, noText: 'Close', noHandler: onClose }}
    >
      <FormProvider {...formMethods}>
        <CompareDateForm scanDateTimes={scanDateTimes} />
        <CompareScanTable customerInbodyIndicators={customerInbodyIndicators} />
      </FormProvider>
    </LbBigModal>
  );
};

export { CompareScanMetricsModal };
