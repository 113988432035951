import React, { ReactNode, useEffect, useMemo } from 'react';

import {
  FieldValues,
  Path,
  PathValue,
  UseControllerProps,
  useController,
} from 'react-hook-form';

import { InputView, InputViewProps } from '../InputView/InputView';
import { Option } from '../../../../general-types';

type ReadOnlyInputProps<T extends FieldValues, TName extends Path<T>> = {
  value?: PathValue<T, TName>;
  options?: Option[];
  renderValue?: (value: PathValue<T, TName>) => ReactNode;
} & Omit<UseControllerProps<T, TName>, 'rules'> &
  Omit<InputViewProps, 'value'>;

const ReadOnlyInput = <T extends FieldValues, TName extends Path<T>>({
  control,
  name,
  value,
  options,
  defaultValue = '' as PathValue<T, TName>,
  shouldUnregister,
  renderValue,
  ...props
}: ReadOnlyInputProps<T, TName>) => {
  const { field } = useController({
    control,
    name,
    defaultValue: value || defaultValue,
    shouldUnregister,
  });

  useEffect(() => {
    if (value !== undefined) {
      field.onChange(value);
    }
  }, [value]); // if add "field" run on every render

  const getShowValue = () => {
    if (renderValue) {
      return renderValue(field.value);
    }

    if (options) {
      const selectedOption = options.find((option) => option.value === field.value);
      return selectedOption ? selectedOption.label : field.value;
    }

    return field.value;
  };

  const showValue = useMemo(() => getShowValue(), [field.value, options]);

  return <InputView {...props} value={showValue} />;
};

export { ReadOnlyInput, ReadOnlyInputProps };
