import React, { ChangeEvent, FocusEvent } from 'react';

import {
  useController,
  UseControllerProps,
  FieldValues,
  Path,
  PathValue,
} from 'react-hook-form';
import { InputBaseProps, TextField, TextFieldProps } from '@mui/material';

export type LbInputProps<T extends FieldValues, TName extends Path<T>> = {
  selectValueOnFocus?: boolean;
  dataTestId?: string;
  InputProps?: InputBaseProps;
} & UseControllerProps<T, TName> &
  Pick<
    TextFieldProps,
    | 'label'
    | 'type'
    | 'variant'
    | 'size'
    | 'helperText'
    | 'className'
    | 'placeholder'
    | 'disabled'
    | 'autoFocus'
    | 'margin'
  >;

const LbInput = <T extends FieldValues, TName extends Path<T>>({
  control,
  name,
  label,
  rules,
  defaultValue = '' as PathValue<T, TName>,
  type,
  InputProps,
  shouldUnregister,
  variant,
  size,
  helperText,
  className,
  placeholder,
  disabled,
  autoFocus,
  selectValueOnFocus,
  dataTestId,
  margin,
}: LbInputProps<T, TName>) => {
  const {
    field: { onChange, ref, ...field },
    fieldState: { error },
  } = useController<any>({
    control,
    name,
    rules,
    defaultValue,
    shouldUnregister,
  });

  const handlerFocus = (event: FocusEvent<HTMLInputElement>) => {
    if (selectValueOnFocus) {
      event.target.select();
    }
  };

  const handlerChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    if (inputValue && type === 'number') {
      onChange(+inputValue);
    } else {
      onChange(inputValue);
    }
  };

  const isRequired = !!rules?.required;

  return (
    <TextField
      {...field}
      onChange={handlerChange}
      inputRef={ref}
      onFocus={handlerFocus}
      label={label}
      helperText={error?.message || helperText}
      error={!!error}
      placeholder={placeholder}
      required={isRequired}
      type={type}
      variant={variant}
      size={size}
      className={className}
      disabled={disabled}
      fullWidth
      autoFocus={autoFocus}
      data-test-id={dataTestId}
      margin={margin}
      slotProps={{
        input: InputProps,
      }}
    />
  );
};

export { LbInput };
