import React, { HTMLAttributes } from 'react';

import {
  FieldValues,
  Path,
  PathValue,
  UseControllerProps,
  useController,
} from 'react-hook-form';
import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteRenderOptionState,
  Checkbox,
  TextField,
  TextFieldProps,
} from '@mui/material';

import { VirtualizedList } from '../VirtualizedList/VirtualizedList';
import { useAutocompleteOneValue } from '../hooks/useAutocompleteOneValue';

type MultipleComboBoxProps<
  T extends FieldValues,
  TName extends Path<T>,
  TOption extends Record<string, string | number>,
> = {
  options: TOption[];
  valueKey?: keyof TOption;
  labelKey?: keyof TOption;
} & UseControllerProps<T, TName> &
  Pick<AutocompleteProps<TOption, true, false, false>, 'disabled' | 'limitTags'> &
  Pick<TextFieldProps, 'label' | 'variant' | 'size' | 'placeholder' | 'margin'>;

const MultipleComboBox = <
  T extends FieldValues,
  TName extends Path<T>,
  TOption extends Record<string, string | number>,
>({
  control,
  name,
  rules,
  defaultValue = [] as PathValue<T, TName>,
  label,
  placeholder,
  options: userOptions,
  valueKey = 'value',
  labelKey = 'label',
  size,
  variant,
  margin,
  disabled,
  limitTags,
}: MultipleComboBoxProps<T, TName, TOption>) => {
  const {
    field: { onChange, ref, ...field },
    fieldState: { error },
  } = useController<any>({
    control,
    name,
    rules,
    defaultValue,
  });

  const { options, getOptionLabel } = useAutocompleteOneValue({
    userOptions,
    valueKey,
    labelKey,
  });

  const getRenderOption = (
    props: HTMLAttributes<HTMLLIElement>,
    value: TOption[keyof TOption],
    state: AutocompleteRenderOptionState,
  ) =>
    ({
      props,
      selected: state.selected,
      label: (
        <>
          <Checkbox checked={state.selected} color="primary" />
          {getOptionLabel(value)}
        </>
      ),
    }) as any;

  return (
    <Autocomplete
      {...field}
      fullWidth
      multiple
      limitTags={limitTags}
      size={size}
      disableCloseOnSelect
      disabled={disabled}
      onChange={(event, value) => onChange(value)}
      options={options}
      getOptionLabel={getOptionLabel}
      renderOption={getRenderOption}
      slotProps={{
        popper: {
          className: 'general-style variant-multiselect',
          placement: 'bottom-start',
        },
        listbox: {
          component: VirtualizedList as any,
        },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          helperText={error?.message}
          error={!!error}
          label={label}
          variant={variant}
          placeholder={placeholder}
          margin={margin}
          inputRef={ref}
        />
      )}
    />
  );
};

export { MultipleComboBox };
