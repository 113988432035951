import React, { useMemo } from 'react';

import classes from './InbodyIndicatorsTableView.module.scss';

import { dateTimeFormat } from '../../../../../../../shared/constants/apiDateFormat';
import { useSort } from '../../../../../../../shared/hooks/useSort';
import { InbodyIndicatorInfo } from '../../../../../../interfaces/inbodyIndicator';
import { LbHorizontalScrollContainer } from '../../../../../../../shared/components/LbHorizontalScrollContainer/LbHorizontalScrollContainer';
import { IndicatorColumnConfig, ScanRowInfo } from './InbodyIndicatorsTableView.config';
import { ScanRow } from './ScanRow/ScanRow';
import { HeaderRow } from './HeaderRow/HeaderRow';
import { getScansInfo } from './utils/getScansInfo';

type InbodyIndicatorsTableViewProps = {
  customerInbodyIndicators: InbodyIndicatorInfo[];
  selectedScanTime: string;
};

const InbodyIndicatorsTableView = ({
  customerInbodyIndicators,
  selectedScanTime,
}: InbodyIndicatorsTableViewProps) => {
  const indicatorColumns: IndicatorColumnConfig[] = useMemo(() => {
    return customerInbodyIndicators.map(({ key, shortLabel, measurement }) => ({
      key,
      shortLabel,
      measurement,
    }));
  }, [customerInbodyIndicators]);

  const scansInfo: ScanRowInfo[] = useMemo(() => {
    return getScansInfo(customerInbodyIndicators, selectedScanTime);
  }, [customerInbodyIndicators, selectedScanTime]);

  const { sortedData: sortedScans, sortMethods } = useSort({
    data: scansInfo,
    dateFormat: dateTimeFormat,
    sortTypeConfig: { scanDateTime: 'DATE' },
    defaultSortInfo: { fieldName: 'scanDateTime', direction: 'DESC' },
  });

  return (
    <LbHorizontalScrollContainer>
      <div
        className={classes.checkInHistoryTable}
        style={{
          display: 'grid',
          gridTemplateColumns: `120px repeat(${indicatorColumns.length}, minmax(max-content, 1fr))`,
        }}
      >
        <HeaderRow indicatorColumns={indicatorColumns} sortMethods={sortMethods} />
        {sortedScans.map((scan) => (
          <ScanRow
            key={scan.scanDateTime}
            scanInfo={scan}
            indicatorColumns={indicatorColumns}
          />
        ))}
      </div>
    </LbHorizontalScrollContainer>
  );
};

export { InbodyIndicatorsTableView };
