import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import classes from './ResultRow.module.scss';
import { InfoCell } from './InfoCell/InfoCell';
import { CheckboxInfoCell } from './CheckboxInfoCell/CheckboxInfoCell';
import { MED_TEST_RESULT_STATUSES_KEYS } from '../../../../../../constants/MED_TEST_RESULT_STATUSES_KEYS';
import { LabTestInfoTooltip } from '../../../../../../components/lab-test-components';

const ResultRow = ({ resultInfo }) => {
  const { testDate, notes, appointmentCompleted, testCompleted, ROF } = resultInfo;
  const {
    APPOINTMENT_COMPLETED,
    TEST_COMPLETED,
    ROF: ROF_KEY,
  } = MED_TEST_RESULT_STATUSES_KEYS;

  const getFormattedDate = (date) => date && moment(date).format('MMM DD YYYY');

  return (
    <div className={classes.gridRow}>
      <div className={classes.resultDateCell}>
        <LabTestInfoTooltip testDate={testDate} />
        <InfoCell label="Test Date" text={getFormattedDate(testDate)} />
      </div>
      <InfoCell label="Notes" text={notes} />
      <CheckboxInfoCell
        isChecked={appointmentCompleted}
        fieldName={APPOINTMENT_COMPLETED}
      />
      <CheckboxInfoCell isChecked={testCompleted} fieldName={TEST_COMPLETED} />
      <CheckboxInfoCell isChecked={ROF} fieldName={ROF_KEY} />
    </div>
  );
};

ResultRow.propTypes = {
  resultInfo: PropTypes.shape({
    testDate: PropTypes.string,
    notes: PropTypes.string,
    appointmentCompleted: PropTypes.bool,
    testCompleted: PropTypes.bool,
    ROF: PropTypes.bool,
  }),
};

export { ResultRow };
