import React, { useMemo, useRef } from 'react';

import classNames from 'classnames';
import { Button, Menu } from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

import classes from './NavMenu.module.scss';
import { useModalToggle } from '../../../../hooks/useModalToggle';
import { useIsActivePage } from '../../../../hooks/useIsActivePage';
import { NavMenuItem } from './NavMenuItem/NavMenuItem';
import { MenuItemSetting } from '../../../../interfaces/system/NavLinkConfig';

type NavMenuProps = {
  label: string;
  menuItems: MenuItemSetting[];
};

const NavMenu = ({ label, menuItems }: NavMenuProps) => {
  const ref = useRef<HTMLButtonElement>(null);
  const [isMenuOpen, toggleMenuOpen] = useModalToggle();

  const pathList = useMemo(() => menuItems.map((item) => item.path), [menuItems]);
  const isActive = useIsActivePage({ urls: pathList });

  const buttonClasses = classNames(classes.button, { [classes.active]: isActive });

  return (
    <>
      <Button
        ref={ref}
        variant="unstyled"
        onClick={toggleMenuOpen}
        disableRipple
        className={buttonClasses}
        endIcon={<KeyboardArrowDownRoundedIcon />}
      >
        {label}
      </Button>
      <Menu
        anchorEl={ref.current}
        open={isMenuOpen}
        onClick={toggleMenuOpen}
        variant="menu"
        className={classes.menuBox}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        slotProps={{ list: { component: 'div' } }}
      >
        {menuItems?.map((item) => <NavMenuItem settings={item} key={item.label} />)}
      </Menu>
    </>
  );
};

export { NavMenu };
