import React, { useEffect, useState } from 'react';

import { angularize } from 'react-in-angularjs';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Container } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { Dictionary, keyBy } from 'lodash';
import { useToggle } from 'react-use';

import { PageTitle } from '../../../../../shared/components/ui-components';

import { MealSchedule } from '../../../../interfaces/MealTimes';
import { SystemProviders } from '../../../../../shared/providers';
import { MealPlan } from '../../../../interfaces/MealPlan';
import { mealPlanService } from '../../../../services/mealPlanService';
import { Option } from '../../../../../general-types';
import { TimingChoiceModal } from '../../../../components/TimingChoiceModal/TimingChoiceModal';
import { transformToOptions } from '../../../../../shared/utils/arrayUtils';
import { mealScheduleService } from '../../../../services/mealScheduleService';
import { MealScheduleForm } from './MealScheduleForm/MealScheduleForm';
import { LbTabs, LbTabsList } from '../../../../../shared/components/tabs';
import { ValueOfKey } from '../../../../../utils-types';

const TAB_VALUES = {
  TODAY: 'TODAY',
  TOMORROW: 'TOMORROW',
} as const;

type TabValues = ValueOfKey<typeof TAB_VALUES>;

type NgzMealTimeTabProps = {
  customerId: number;
};

const MealTimeTab = ({ customerId }: NgzMealTimeTabProps) => {
  const [mealPlans, setMealPlans] = useState<Dictionary<MealPlan> | null>(null);
  const [mealPlansOptions, setMealPlansOptions] = useState<Option[]>([]);
  const [mealSchedules, setMealSchedules] = useState<
    Record<TabValues, MealSchedule | null>
  >({ TODAY: null, TOMORROW: null });

  const [currentTab, setCurrentTab] = useState<TabValues>(TAB_VALUES.TODAY);
  const [isShowSavedModal, toggleShowSavedModal] = useToggle(false);

  const formMethods = useForm<MealSchedule>({
    values: mealSchedules[currentTab] || undefined,
    shouldUnregister: false, // it crucial to send all data to the server, not exist data will be replaced by null
  });
  const { handleSubmit } = formMethods;

  const getMealPlansInfo = async () => {
    const userMealPlans = await mealPlanService.getMealPlansMacros(customerId);

    const mealPlan = transformToOptions({
      list: userMealPlans.mealPlans,
      valueKey: 'originId',
      labelKey: 'name',
    });
    const mealPlansObject = keyBy(userMealPlans.mealPlans, 'originId');

    setMealPlans(mealPlansObject);
    setMealPlansOptions(mealPlan);
  };

  const getMealSchedules = async () => {
    const todayMealSchedule = await mealScheduleService.getMealSchedule(customerId);

    if (todayMealSchedule.validTo) {
      const tomorrowMealSchedule = await mealScheduleService.getMealSchedule(
        customerId,
        true,
      );

      setMealSchedules({ TODAY: todayMealSchedule, TOMORROW: tomorrowMealSchedule });
      setCurrentTab(TAB_VALUES.TOMORROW);
    } else {
      setMealSchedules({ TODAY: todayMealSchedule, TOMORROW: null });
      setCurrentTab(TAB_VALUES.TODAY);
    }
  };

  useEffect(() => {
    getMealPlansInfo();
    getMealSchedules();
  }, []);

  const onSubmit = async (data: MealSchedule, setImmediately: boolean) => {
    toggleShowSavedModal();
    const mealSchedule = await mealScheduleService.updateMealSchedule(customerId, {
      ...data,
      setImmediately,
    });

    if (setImmediately) {
      setMealSchedules({ TODAY: mealSchedule, TOMORROW: null });
      setCurrentTab(TAB_VALUES.TODAY);
    } else {
      setMealSchedules({ TODAY: mealSchedules.TODAY, TOMORROW: mealSchedule });
      setCurrentTab(TAB_VALUES.TOMORROW);
    }

    enqueueSnackbar('Meal schedule changes saved', { variant: 'success' });
  };

  const handleTabChange = (tab: TabValues) => {
    setCurrentTab(tab);
  };

  if (!mealPlans) {
    return null;
  }

  const isEditDisabled = !!mealSchedules.TOMORROW && currentTab === TAB_VALUES.TODAY;

  return (
    <LbTabs value={currentTab} onChange={(e, tab) => handleTabChange(tab as TabValues)}>
      <PageTitle
        title="Meal Time"
        tabs={
          !!mealSchedules.TOMORROW && (
            <LbTabsList
              variant="underline"
              tabsConfig={[
                { label: 'Today', value: TAB_VALUES.TODAY },
                {
                  label: 'Adjusted Meal Times Starting Tomorrow',
                  value: TAB_VALUES.TOMORROW,
                },
              ]}
            />
          )
        }
      >
        {!isEditDisabled && (
          <Button
            variant="contained"
            color="primary"
            size="medium"
            onClick={toggleShowSavedModal}
          >
            Save
          </Button>
        )}
      </PageTitle>
      <FormProvider {...formMethods}>
        <MealScheduleForm
          mode={isEditDisabled ? 'VIEW' : 'EDIT'}
          mealPlans={mealPlans}
          mealPlansOptions={mealPlansOptions}
        />
      </FormProvider>
      <TimingChoiceModal
        open={isShowSavedModal}
        title="How would you like to change the meal schedule?"
        onSubmit={handleSubmit((data) => onSubmit(data, true))}
        onTomorrowSubmit={handleSubmit((data) => onSubmit(data, false))}
        onClose={toggleShowSavedModal}
      />
    </LbTabs>
  );
};

// need this fro performance (omit system providers rerender)
const NgzMealTimeTab = ({ customerId }: NgzMealTimeTabProps) => {
  return (
    <SystemProviders>
      <Container>
        <MealTimeTab customerId={customerId} />
      </Container>
    </SystemProviders>
  );
};

angularize(NgzMealTimeTab, 'ngzMealTimeTab', angular.module('app.customerManagement'), {
  customerId: '<',
});
