import React from 'react';

import classes from './HeaderRow.module.scss';
import {
  GridTableCell,
  GridTableRow,
} from '../../../../../../../../shared/components/table-components';
import { SortButtons } from '../../../../../../../../shared/components/buttons';
import { IndicatorColumnConfig } from '../InbodyIndicatorsTableView.config';
import { SortMethods } from '../../../../../../../../shared/hooks/useSort';
import { TitleWithDescription } from '../../../../../../../../shared/components/text-formation-components';

type HeaderRowProps = {
  indicatorColumns: IndicatorColumnConfig[];
  sortMethods: SortMethods<'scanDateTime'>;
};

export const HeaderRow = ({ indicatorColumns, sortMethods }: HeaderRowProps) => {
  return (
    <GridTableRow variant="header" className={classes.header}>
      <GridTableCell>
        <SortButtons
          fieldName="scanDateTime"
          onSort={sortMethods.handleSort}
          getIsActive={sortMethods.getIsSortedBy}
        >
          Date
        </SortButtons>
      </GridTableCell>
      {indicatorColumns.map((column) => (
        <GridTableCell key={column.key} className={classes.titleCell}>
          <TitleWithDescription
            title={column.shortLabel}
            description={column.measurement}
            titleFont="body-14-bold"
            withoutGap
          />
        </GridTableCell>
      ))}
    </GridTableRow>
  );
};
