import React from 'react';

import classNames from 'classnames';
import { Button, ButtonProps } from '@mui/material';

import classes from './ConfirmButtonsBlock.module.scss';

type ConfirmButtonsBlockProps = {
  onCancel: () => void;
  onConfirm: () => void;
  yesDisabled?: boolean;
  className?: string;
  size?: ButtonProps['size'];
  noText?: string;
  yesText?: string;
  isShowNo?: boolean;
};

const ConfirmButtonsBlock = ({
  onCancel,
  onConfirm,
  yesDisabled,
  className,
  size = 'small',
  noText = 'Cancel',
  yesText = 'OK',
  isShowNo = true,
}: ConfirmButtonsBlockProps) => {
  const containerClassName = classNames(classes.container, className);

  return (
    <div className={containerClassName}>
      {isShowNo && (
        <Button variant="outlined" onClick={onCancel} size={size}>
          {noText}
        </Button>
      )}
      <Button
        variant="contained"
        type="submit"
        onClick={onConfirm}
        disabled={yesDisabled}
        size={size}
      >
        {yesText}
      </Button>
    </div>
  );
};

export { ConfirmButtonsBlock };
