import React from 'react';
import {
  GridTableCell,
  GridTableRow,
} from '../../../../../../../../shared/components/table-components';
import { getFormattedDate } from '../../../../../../../../shared/utils/dateUtils';
import { getRoundedNumberOrDash } from '../../../../../../../../shared/utils/mathUtils';
import { IndicatorColumnConfig, ScanRowInfo } from '../InbodyIndicatorsTableView.config';
import {
  dateTimeFormat,
  localDataFormat,
} from '../../../../../../../../shared/constants/apiDateFormat';

type ScanRowProps = {
  scanInfo: ScanRowInfo;
  indicatorColumns: IndicatorColumnConfig[];
};

export const ScanRow = ({ scanInfo, indicatorColumns }: ScanRowProps) => {
  return (
    <GridTableRow variant="row">
      <GridTableCell variant="title">
        {getFormattedDate(scanInfo.scanDateTime, localDataFormat, dateTimeFormat)}
      </GridTableCell>
      {indicatorColumns.map((column) => (
        <GridTableCell key={column.key}>
          {getRoundedNumberOrDash(scanInfo.indicatorValues[column.key])}
        </GridTableCell>
      ))}
    </GridTableRow>
  );
};
