import {
  dateTimeFormat,
  localDataFormat,
} from '../../../../../../../../shared/constants/apiDateFormat';
import { InbodyType } from '../../../../../../../../shared/constants/inbody-info/InbodyType';
import {
  getFormattedDate,
  isSameDay,
} from '../../../../../../../../shared/utils/dateUtils';
import { getHumanizeInbodyType } from '../../../../../../../../shared/utils/inbodyUtils';
import { Flags } from '../../../../../../../../utils-types';
import {
  INBODY_INDICATORS_KEY,
  InbodyIndicatorsKey,
} from '../../../../../../../constants/inbodyIndecatorsKey';
import { IndicatorScanInfo } from '../../../../../../../interfaces/inbodyIndicator';
import { MealPlanOnScanDayInfo } from '../../../../../../../interfaces/mealPlanOnScanDayInfo';

type GetDataLabelsProps = {
  scans: IndicatorScanInfo[];
  indicatorKey: InbodyIndicatorsKey;
  mealPlansOnScanDay: MealPlanOnScanDayInfo[];
  customerInbodyTypeFlags: Flags<InbodyType>;
};

export const getDataLabels = ({
  scans,
  indicatorKey,
  mealPlansOnScanDay,
  customerInbodyTypeFlags,
}: GetDataLabelsProps) =>
  scans.map(({ scanDateTime, inbodyType }) => {
    const scanLabel = [getFormattedDate(scanDateTime, localDataFormat, dateTimeFormat)];

    if (!customerInbodyTypeFlags.NON_INBODY) {
      scanLabel.push(getHumanizeInbodyType(inbodyType));
    }

    if (indicatorKey === INBODY_INDICATORS_KEY.WEIGHT) {
      const mealPlan = mealPlansOnScanDay.find(({ date }) =>
        isSameDay(date, scanDateTime),
      );

      if (mealPlan) {
        scanLabel.push(
          '',
          `prot. ${mealPlan.proteins}`,
          `carbs ${mealPlan.carbs}`,
          `fats ${mealPlan.fats}`,
        );
      }
    }

    return scanLabel;
  });
