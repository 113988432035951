import React, { HTMLAttributes, ReactNode } from 'react';
import {
  FieldValues,
  Path,
  PathValue,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteRenderOptionState,
  Checkbox,
  TextField,
  TextFieldProps,
} from '@mui/material';

import { VirtualizedList } from '../VirtualizedList/VirtualizedList';

type LbMultipleAutocompleteProps<
  T extends FieldValues,
  TName extends Path<T>,
  TOption,
> = UseControllerProps<T, TName> &
  Pick<AutocompleteProps<TOption, true, false, false>, 'disabled' | 'limitTags'> & {
    options: TOption[];
    valueKey: keyof TOption;
    labelKey: keyof TOption;
    renderOptionLabel?: (option: TOption) => ReactNode;
  } & Pick<TextFieldProps, 'label' | 'variant' | 'size' | 'placeholder' | 'margin'>;

const LbMultipleAutocomplete = <T extends FieldValues, TName extends Path<T>, TOption>({
  control,
  name,
  rules,
  defaultValue = [] as PathValue<T, TName>,
  label,
  placeholder,
  margin,
  options,
  size,
  variant,
  disabled,
  limitTags,
  shouldUnregister,
  renderOptionLabel,
  valueKey,
  labelKey,
}: LbMultipleAutocompleteProps<T, TName, TOption>) => {
  const {
    field: { onChange, ref, ...field },
    fieldState: { error },
  } = useController<any>({
    control,
    name,
    rules,
    defaultValue,
    shouldUnregister,
  });

  const getOptionLabel = (option: TOption) =>
    labelKey && typeof option[labelKey] === 'string' ? (option[labelKey] as string) : '';

  const getIsOptionEqualToValue = (option: TOption, value: TOption) =>
    !!valueKey && option[valueKey] === value[valueKey];

  const renderOption = (
    props: HTMLAttributes<HTMLLIElement>,
    option: TOption,
    state: AutocompleteRenderOptionState,
  ) =>
    ({
      props,
      selected: state.selected,
      label: (
        <>
          <Checkbox checked={state.selected} color="primary" />
          {renderOptionLabel ? renderOptionLabel(option) : getOptionLabel(option)}
        </>
      ),
    }) as any;

  return (
    <Autocomplete
      {...field}
      fullWidth
      multiple
      limitTags={limitTags}
      size={size}
      disableCloseOnSelect
      disabled={disabled}
      onChange={(event, value) => onChange(value)}
      options={options}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={getIsOptionEqualToValue}
      renderOption={renderOption}
      slotProps={{
        popper: {
          className: 'general-style variant-multiselect',
          placement: 'bottom-start',
        },
        listbox: {
          component: VirtualizedList as any,
        },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          helperText={error?.message}
          error={!!error}
          label={label}
          variant={variant}
          placeholder={placeholder}
          margin={margin}
          inputRef={ref}
        />
      )}
    />
  );
};

export { LbMultipleAutocomplete };
