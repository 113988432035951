import { SyntheticEvent } from 'react';

import { FilterOptionsState, createFilterOptions } from '@mui/material';

type UseCreatableAutocompleteProps<
  TOption extends Record<string, string | number>,
  TValueKey extends keyof TOption,
  TLabelKey extends keyof TOption,
> = {
  valueKey: TValueKey;
  labelKey: TLabelKey;
  prepareAddedOption: (value: string) => Promise<TOption> | TOption;
  onChange: (value: TOption) => void;
};

export const useCreatableAutocomplete = <
  TOption extends Record<string, string | number>,
  TValueKey extends keyof TOption,
  TLabelKey extends keyof TOption,
>({
  labelKey,
  valueKey,
  onChange,
  prepareAddedOption,
}: UseCreatableAutocompleteProps<TOption, TValueKey, TLabelKey>) => {
  type CreatableOption = TOption & { inputValue?: string };

  const filter = createFilterOptions<CreatableOption>();

  const handleChange = async (
    event: SyntheticEvent,
    newValue: string | CreatableOption,
  ) => {
    if (typeof newValue === 'string') {
      const option = await prepareAddedOption(newValue);
      onChange(option);
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      const option = await prepareAddedOption(newValue.inputValue);

      onChange(option);
    } else {
      onChange(newValue);
    }
  };

  const filterOptions = (
    options: CreatableOption[],
    params: FilterOptionsState<CreatableOption>,
  ): CreatableOption[] => {
    const filtered = filter(options, params);

    const { inputValue } = params;
    // Suggest the creation of a new value
    const isExisting = options.some((option) => inputValue === option.name);
    if (inputValue !== '' && !isExisting) {
      const newOption = {
        inputValue,
        [labelKey]: `Add "${inputValue}"`,
        [valueKey]: 'new added',
      } as CreatableOption;

      filtered.push(newOption);
    }

    return filtered;
  };

  const getOptionLabel = (option: string | CreatableOption): string => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option[labelKey] as string;
  };

  return { handleChange, filterOptions, getOptionLabel };
};
