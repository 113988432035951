import { enqueueSnackbar } from "notistack";
import { getEnabledModules } from "../../modules/shared/utils/paidModulesUtils";

(function () {
    'use strict';

    angular.module('app.coachEdit').controller('CoachEditController', CoachEditController);

    CoachEditController.$inject = [
        '$scope',
        '$routeParams',
        'utilService',
        'ModalService',
        'CoachService',
        'GeneralService',
        'constants',
    ];

    function CoachEditController($scope, $routeParams, utilService, ModalService, CoachService,
                                 GeneralService, constants) {
        var vm = this;

        vm.loggedInUser = utilService.getLoggedInUserData();
        vm.states = constants.states;
        vm.patterns = constants.enums.patterns;
        vm.tabName = 'coachDetails';
        vm.coachPassword = {};
        vm.createCoach = {
            additional: {
                ableToGiveDiscount: false
            }
        };
        vm.initialCoach = {};
        vm.availableEntities = [];
        vm.spinner = {active: false};
        vm.isActiveCalendarEnabled = false;
        vm.paymentFlowType = {};

        vm.goBack = goBack;
        vm.activateOrDeactivateCoach = activateOrDeactivateCoach;
        vm.getCoachForEntity = getCoachForEntity;
        vm.showDeleteCoachDialog = showDeleteCoachDialog;
        vm.deleteCurrentUser = deleteCurrentUser;
        vm.setPaymentFlowType = setPaymentFlowType;
        vm.setIsActiveCalendarEnabled = setIsActiveCalendarEnabled;
        vm.updateCoachInfo = updateCoachInfo;
        vm.updateCoachBio = updateCoachBio;
        vm.setCoachInfo = setCoachInfo;

        vm.$onInit =() => {            
            vm.getCoachForEntity();
        }

        function goBack() {
            utilService.goBackWithParam('/coach-list', constants.pages.coachList);
        }

        function showDeleteCoachDialog() {
            ModalService.confirm('Are you sure you want to delete an user?',
                vm.deleteCurrentUser, $routeParams.id, null, 'Cancel', 'Delete')
        }

        function deleteCurrentUser(id) {
            vm.spinner.active = true;
            GeneralService.deleteUserBySuper(id).then(function (response) {
                vm.spinner.active = false;
                if (response.status === 200) {
                    utilService.goTo('/coach-list');
                }
            })
        }

        function activateOrDeactivateCoach() {
            vm.spinner.active = true;
            GeneralService.disableEnableUser($routeParams.id, {active: !vm.initialCoach.active}).then(function (res) {
                if (res.status === 200) {
                    const updatedCoach = { ...vm.initialCoach, active: res.data.active };

                    vm.createCoach = angular.copy(updatedCoach);
                    vm.coachName = angular.copy(updatedCoach);
                    vm.initialCoach = angular.copy(updatedCoach);
                }
            }).finally(() => {
                vm.spinner.active = false;
            });
        }

        function updateCoachInfo(data) {
            vm.spinner.active = true;
            CoachService.updateCoachByEntity($routeParams.id, data).then(function (res) {
                
                if (res.status === 200) {
                    vm.setCoachInfo(res.data);
                    enqueueSnackbar('Coach info updated successfully', { variant: 'success' });
                }
            }).finally(() => {
                vm.spinner.active = false;
            });
        }

        function updateCoachBio(bio) {
            const updateCoachInfo = {
                ...vm.initialCoach,
                additional: {
                    ...vm.initialCoach.additional,
                    ...bio,
                },
            };
            
            vm.setCoachInfo(updateCoachInfo);
        }

        function setCoachInfo(coachInfo) {
            vm.initialCoach = angular.copy(coachInfo);
            vm.createCoach = angular.copy(coachInfo);
            vm.coachName = angular.copy(coachInfo);

            utilService.applyAsyncFunc($scope);
        }

        function getCoachForEntity() {
            CoachService.getCoachForEntity($routeParams.id).then(function (res) {
                if (res.status === 200) {
                    vm.createCoach = res.data;
                    vm.coachName = angular.copy(vm.createCoach);
                    vm.initialCoach = angular.copy(vm.createCoach);
                    vm.availableEntities = [vm.createCoach.entity];

                    vm.setIsActiveCalendarEnabled();
                    vm.setPaymentFlowType();
                }
            });
        }

        function setPaymentFlowType() {
            const paymentFlowTypeConfig = constants.enums.paymentFlowType;
            const paymentFlowType = vm.createCoach?.entity?.paymentFlowType;

            vm.paymentFlowType = utilService.getFlagsObject(paymentFlowTypeConfig, [paymentFlowType])
        }

        async function setIsActiveCalendarEnabled() {
            const enabledModules = await getEnabledModules(vm.createCoach.gymId);
            vm.isActiveCalendarEnabled = enabledModules.ACTIVE_CALENDAR;

            $scope.$apply();
        }
    }
})();
