import {
  dateTimeFormat,
  localDateTimeFormat,
} from '../../../../../../../shared/constants/apiDateFormat';
import { addAllOption } from '../../../../../../../shared/utils/arrayUtils';
import { getFormattedDate } from '../../../../../../../shared/utils/dateUtils';
import { InbodyIndicatorInfo } from '../../../../../../interfaces/inbodyIndicator';
import { getScanDates } from '../../utils/getScanDates';

export const getScanDateOptions = (customerInbodyIndicators: InbodyIndicatorInfo[]) => {
  const scanDates = getScanDates(customerInbodyIndicators);

  const scanDateOptions = scanDates.map((date) => ({
    label: getFormattedDate(date, localDateTimeFormat, dateTimeFormat),
    value: date,
  }));

  return addAllOption(scanDateOptions, true);
};
