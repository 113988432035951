import moment from 'moment';
import { InbodyIndicatorInfo } from '../../../../../interfaces/inbodyIndicator';
import { dateTimeFormat } from '../../../../../../shared/constants/apiDateFormat';

export const getScanDates = (customerInbodyIndicators: InbodyIndicatorInfo[]) => {
  const scanDates = new Set<string>();

  customerInbodyIndicators.forEach((indicator) => {
    indicator.scans.forEach((scan) => {
      scanDates.add(scan.scanDateTime);
    });
  });

  const sortedScanDates = Array.from(scanDates).sort((a, b) =>
    moment(a, dateTimeFormat) < moment(b, dateTimeFormat) ? 1 : -1,
  );

  return sortedScanDates;
};
