import React from 'react';
import PropTypes from 'prop-types';

import { Autocomplete } from '@mui/material';
import { observer } from 'mobx-react';

import classes from './SupplementSearch.module.scss';
import {
  prepSupplementsToRender,
  renderInput,
  renderOption,
} from './SupplementSearch.settings';
import { customPaperStyle } from './SupplementSearch.styled.sx';
import { supplementsStore } from '../../../stores/supplementsStore';

const SupplementSearch = observer(({ selectedSupplements, onChange, addBtnHandler }) => {
  const { supplements, lastAdded } = supplementsStore;

  const supplementsOptions = prepSupplementsToRender(supplements, lastAdded);

  return (
    <div className={classes.inputBox}>
      {selectedSupplements.length !== 0 && (
        <button type="button" className={classes.addBtn} onClick={addBtnHandler}>
          Add {selectedSupplements.length}
        </button>
      )}
      <Autocomplete
        value={selectedSupplements}
        onChange={(event, data) => onChange(data)}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        multiple
        disableCloseOnSelect
        clearIcon="Cancel"
        popupIcon={null}
        noOptionsText="Supplement not found"
        renderOption={renderOption}
        getOptionLabel={(option) => option.name_only}
        options={supplementsOptions}
        groupBy={(option) => option.firstLetter}
        slotProps={{
          chip: { variant: 'supplement' },
          paper: { sx: customPaperStyle },
        }}
        renderInput={renderInput}
      />
    </div>
  );
});

SupplementSearch.propTypes = {
  selectedSupplements: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  addBtnHandler: PropTypes.func.isRequired,
};

export { SupplementSearch };
