import React, { ReactNode } from 'react';

import { useFormContext } from 'react-hook-form';

import classes from './MealTimesTable.module.scss';
import { HeaderRow } from './HeaderRow/HeaderRow';
import { Day } from '../../../shared/constants/daysOfWeek';
import { MealName } from '../../../shared/constants/mealNames';
import { GetIsDisabledTime, MealTimeRow } from './MealTimeRow/MealTimeRow';
import { MealSchedule } from '../../interfaces/MealTimes';
import { LbSelect, ReadOnlyInput } from '../../../shared/components/form-components';
import { Option, Mode } from '../../../general-types';

type MealTimesTableProps = {
  mode?: Mode;
  mealNames: MealName[];
  mealFormKey: 'mealTime' | 'workoutMealTime';
  mealTitle: ReactNode;
  mealPlansOptions?: Option[];
  renderDayTitle: (day: Day) => ReactNode;
  getIsDisabledTime?: GetIsDisabledTime;
};

const MealTimesTable = ({
  mode = 'EDIT',
  mealNames,
  mealFormKey,
  mealTitle,
  mealPlansOptions,
  renderDayTitle,
  getIsDisabledTime,
}: MealTimesTableProps) => {
  const { control } = useFormContext<MealSchedule>();

  return (
    <div className={classes.container}>
      <HeaderRow mealTitle={mealTitle} renderDayTitle={renderDayTitle} />
      {mealPlansOptions && (
        <HeaderRow
          mealTitle="Meal Plan Assignment"
          renderDayTitle={(day) =>
            mode === 'EDIT' ? (
              <LbSelect
                control={control}
                name={`${day}.mealPlanOrigin`}
                options={mealPlansOptions}
                size="small"
                helperText={null}
              />
            ) : (
              <ReadOnlyInput
                control={control}
                name={`${day}.mealPlanOrigin`}
                options={mealPlansOptions}
              />
            )
          }
        />
      )}
      {mealNames.map((mealName) => (
        <MealTimeRow
          mode={mode}
          key={mealName}
          mealName={mealName}
          mealFormKey={mealFormKey}
          getIsDisabledTime={getIsDisabledTime}
        />
      ))}
    </div>
  );
};

export { MealTimesTable };
