import React from 'react';
import { Button, MenuItem, TextField } from '@mui/material';
import { observer } from 'mobx-react';

import classes from './TitleBlock.module.scss';
import { TextFieldIcon } from '../../../../../shared/components/form-components/LbSelect/LbSelect.settings';
import { lbSelectMenuStyles } from '../../../../../shared/components/form-components/LbSelect/LbSelect.styles';
import { PromoCodeStatus, statusFilterOptions } from '../../../../constants/promoCodes';
import { loggedInUserDataStore } from '../../../../../shared/stores/loggedInUserDataStore';
import { PageTitle } from '../../../../../shared/components/ui-components';
import { addAllOption } from '../../../../../shared/utils/arrayUtils';

type TitleBlockProps = {
  filterValue: string | null;
  onChangeFilterValue: (value: PromoCodeStatus | null) => void;
  onAddPromoCodeButtonClick: () => void;
};

const TitleBlock = observer(
  ({ filterValue, onChangeFilterValue, onAddPromoCodeButtonClick }: TitleBlockProps) => {
    const { isEntity } = loggedInUserDataStore;
    const handleChangeFilterValue = (event: React.ChangeEvent<{ value: string }>) => {
      return onChangeFilterValue(event.target.value as PromoCodeStatus);
    };

    return (
      <PageTitle title="Promo codes" customTopMargin>
        <div className={classes.titleControls}>
          <TextField
            className={classes.filterInput}
            select
            value={filterValue || ''}
            label="Status"
            variant="outlined"
            onChange={handleChangeFilterValue}
            fullWidth
            slotProps={{
              select: {
                IconComponent: TextFieldIcon,
                displayEmpty: true,
                MenuProps: { sx: lbSelectMenuStyles },
              },

              inputLabel: {
                shrink: true,
              },
            }}
          >
            {addAllOption(statusFilterOptions).map((option) => (
              <MenuItem value={option.value} key={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          {isEntity && (
            <Button variant="contained" size="medium" onClick={onAddPromoCodeButtonClick}>
              + Add a Promo Code
            </Button>
          )}
        </div>
      </PageTitle>
    );
  },
);

export { TitleBlock };
