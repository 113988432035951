import React from 'react';

import { Checkbox, CheckboxProps } from '@mui/material';
import { FieldValues, Path, useController, UseControllerProps } from 'react-hook-form';
import {
  LbFormControlLabel,
  LbFormControlLabelProps,
} from '../LbFormControlLabel/LbFormControlLabel';

type LbCheckboxProps<T extends FieldValues, TName extends Path<T>> = Omit<
  UseControllerProps<T, TName>,
  'defaultValue'
> &
  Pick<CheckboxProps, 'disabled' | 'defaultChecked' | 'color'> &
  Pick<LbFormControlLabelProps, 'className' | 'label' | 'align' | 'font'>;

const LbCheckbox = <T extends FieldValues, TName extends Path<T>>({
  control,
  name,
  defaultChecked = false,
  label,
  className,
  rules,
  disabled,
  color,
  align,
  font,
  shouldUnregister,
}: LbCheckboxProps<T, TName>) => {
  const {
    field: { value, onChange, onBlur, ref, name: fieldName },
  } = useController<any>({
    control,
    name,
    defaultValue: defaultChecked,
    rules,
    shouldUnregister,
  });

  return (
    <LbFormControlLabel
      control={
        <Checkbox
          checked={value}
          onChange={(event) => onChange(event.target.checked)}
          slotProps={{ input: { ref } }}
          name={fieldName}
          onBlur={onBlur}
          disabled={disabled}
          color={color}
        />
      }
      label={label}
      className={className}
      align={align}
      font={font}
    />
  );
};

export { LbCheckbox };
