import React from 'react';
import { useFormContext } from 'react-hook-form';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { MealDaysFormFieldName, MealSchedule } from '../../../interfaces/MealTimes';
import { GridTableCell } from '../../../../shared/components/table-components';
import {
  LbTimePicker,
  ReadOnlyInput,
} from '../../../../shared/components/form-components';
import { Mode } from '../../../../general-types';
import { getFormattedDate } from '../../../../shared/utils/dateUtils';
import { localTimeFormat, timeFormat } from '../../../../shared/constants/apiDateFormat';

type MealTimeCellProps = {
  mode: Mode;
  fieldName: MealDaysFormFieldName;
  disabled?: boolean;
};

const MealTimeCell = ({ mode, fieldName, disabled }: MealTimeCellProps) => {
  const { control } = useFormContext<MealSchedule>();

  return mode === 'EDIT' ? (
    <GridTableCell align="bottom">
      <LbTimePicker
        control={control}
        name={fieldName}
        disabled={disabled}
        size="small"
        rules={{
          required: disabled ? false : 'This field is required',
        }}
        openPickerIcon={ArrowDropDownIcon}
      />
    </GridTableCell>
  ) : (
    <GridTableCell>
      <ReadOnlyInput
        control={control}
        name={fieldName}
        disabled={disabled}
        renderValue={(time) =>
          time ? getFormattedDate(time, localTimeFormat, timeFormat) : 'n/a'
        }
      />
    </GridTableCell>
  );
};

export { MealTimeCell };
