import React from 'react';

import {
  MenuItem,
  SelectProps,
  TextField,
  TextFieldProps,
  InputBaseProps,
} from '@mui/material';

import {
  FieldValues,
  Path,
  PathValue,
  UseControllerProps,
  useController,
} from 'react-hook-form';
import { TextFieldIcon } from './LbSelect.settings';
import { lbSelectMenuStyles } from './LbSelect.styles';
import { Option } from '../../../../general-types';

type LbSelectProps<T extends FieldValues, TName extends Path<T>> = {
  options: Option[];
  dataTestId?: string;
  InputProps?: InputBaseProps;
} & UseControllerProps<T, TName> &
  Pick<
    TextFieldProps,
    'label' | 'variant' | 'size' | 'helperText' | 'className' | 'disabled' | 'margin'
  > &
  Pick<SelectProps, 'displayEmpty'>;

const LbSelect = <T extends FieldValues, TName extends Path<T>>({
  control,
  name,
  rules,
  defaultValue = '' as PathValue<T, TName>,
  options,
  label,
  variant,
  size,
  margin,
  helperText,
  className,
  disabled,
  displayEmpty,
  dataTestId,
  shouldUnregister,
  InputProps,
}: LbSelectProps<T, TName>) => {
  const {
    field: { value, ref, ...field },
    fieldState: { error },
  } = useController<any>({ control, name, rules, defaultValue, shouldUnregister });

  const isRequired = !!rules?.required;

  return (
    <TextField
      select
      {...field}
      value={value}
      inputRef={ref}
      label={label}
      helperText={error?.message || helperText}
      error={!!error}
      required={isRequired}
      fullWidth
      size={size}
      variant={variant}
      margin={margin}
      className={className}
      disabled={disabled}
      data-test-id={dataTestId}
      slotProps={{
        input: InputProps,
        select: {
          IconComponent: TextFieldIcon,
          displayEmpty,
          MenuProps: { sx: lbSelectMenuStyles },
        },
        inputLabel: {
          shrink: displayEmpty,
        },
      }}
    >
      {options.map((option) => (
        <MenuItem value={option.value} key={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export { LbSelect };
