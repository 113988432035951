import React, { HTMLAttributes } from 'react';

import {
  FieldValues,
  Path,
  PathValue,
  UseControllerProps,
  useController,
} from 'react-hook-form';
import {
  TextField,
  Autocomplete,
  AutocompleteProps,
  TextFieldProps,
  AutocompleteRenderOptionState,
  InputBaseProps,
} from '@mui/material';
import { VirtualizedList } from '../VirtualizedList/VirtualizedList';
import { useAutocompleteOneValue } from '../hooks/useAutocompleteOneValue';

type ComboBoxProps<
  T extends FieldValues,
  TName extends Path<T>,
  TOption extends Record<string, string | number>,
  TDisableClearable extends boolean | undefined,
> = {
  options: TOption[];
  valueKey?: keyof TOption;
  labelKey?: keyof TOption;
  dataTestId?: string;
  InputProps?: InputBaseProps;
} & UseControllerProps<T, TName> &
  Pick<
    AutocompleteProps<TOption, true, TDisableClearable, false>,
    'disableClearable' | 'disabled' | 'loading' | 'className'
  > &
  Pick<TextFieldProps, 'label' | 'variant' | 'size' | 'margin'>;

const ComboBox = <
  T extends FieldValues,
  TName extends Path<T>,
  TOption extends Record<string, string | number>,
  TDisableClearable extends boolean | undefined,
>({
  control,
  name,
  rules,
  defaultValue = null as PathValue<T, TName>,
  options: userOptions,
  label,
  shouldUnregister,
  valueKey = 'value',
  labelKey = 'label',
  size,
  disabled,
  disableClearable = true,
  variant,
  dataTestId,
  loading,
  margin,
  className,
  InputProps,
}: ComboBoxProps<T, TName, TOption, TDisableClearable>) => {
  const {
    field: { ref, ...field },
    fieldState: { error },
  } = useController<any>({
    control,
    name,
    rules,
    defaultValue,
    shouldUnregister,
  });

  const { options, getOptionLabel } = useAutocompleteOneValue({
    userOptions,
    valueKey,
    labelKey,
  });

  const getRenderOption = (
    props: HTMLAttributes<HTMLLIElement>,
    value: TOption[keyof TOption],
    state: AutocompleteRenderOptionState,
  ) =>
    ({
      props,
      label: getOptionLabel(value),
      selected: state.selected,
    }) as any;

  const isRequired = !!rules?.required;

  return (
    <Autocomplete
      {...field}
      tabIndex={-1}
      fullWidth
      size={size}
      onChange={(event, value) => field.onChange(value)}
      loading={loading}
      disabled={disabled}
      disableClearable={disableClearable}
      options={options}
      getOptionLabel={getOptionLabel}
      renderOption={getRenderOption}
      data-test-id={dataTestId}
      className={className}
      slotProps={{
        popper: { className: 'general-style variant-select', placement: 'bottom-start' },
      }}
      slots={{
        listbox: VirtualizedList as any,
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            slotProps={{
              input: { ...params.InputProps, ...InputProps },
            }}
            helperText={error?.message}
            error={!!error}
            label={label}
            required={isRequired}
            variant={variant}
            margin={margin}
            inputRef={ref}
          />
        );
      }}
    />
  );
};

export { ComboBox };
