import React, { useMemo } from 'react';

import { Button, Divider, Paper } from '@mui/material';
import { useToggle } from 'react-use';

import { useFormContext } from 'react-hook-form';
import ShowChartIcon from '@mui/icons-material/ShowChartRounded';
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';

import classes from './InbodyIndicatorsTab.module.scss';
import { ComboBox, LbSelect } from '../../../../../../shared/components/form-components';
import {
  LbTabPanel,
  LbTabs,
  LbTabsList,
  TabsConfig,
} from '../../../../../../shared/components/tabs';
import { InbodyIndicatorsTableView } from './InbodyIndicatorsTableView/InbodyIndicatorsTableView';
import { InbodyIndicatorsChartView } from './InbodyIndicatorsChartView/InbodyIndicatorsChartView';
import { CustomerParam } from './CustomerParam/CustomerParam';
import { InbodyIndicatorInfo } from '../../../../../interfaces/inbodyIndicator';
import { Option } from '../../../../../../general-types';
import { getScanDateOptions } from './utils/getScanDateOptions';
import { InbodyIndicatorsOptions } from './InbodyIndicatorsOptions/InbodyIndicatorsOptions';
import { SocialMediaScanMetricsModal } from '../SocialMediaScanMetricsModal/SocialMediaScanMetricsModal';
import { CompareScanMetricsModal } from '../CompareScanMetricsModal/CompareScanMetricsModal';
import { MealPlanOnScanDayInfo } from '../../../../../interfaces/mealPlanOnScanDayInfo';
import {
  InbodyType,
  INBODY_TYPES,
} from '../../../../../../shared/constants/inbody-info/InbodyType';
import { getFlagsObject } from '../../../../../../shared/utils/sortingAndGroupingUtil';
import { InbodyIndicatorsFormValues } from '../NgzInbodyIndicatorsTab.settings';
import { openScanSummary } from './utils/openScanSummary';
import { getScanDates } from '../utils/getScanDates';

const CheckInViewTabs = {
  CHART: 'CHART',
  TABLE: 'TABLE',
} as const;
const { CHART, TABLE } = CheckInViewTabs;

type CustomerInfo = {
  id: number;
  age: number;
  gender: string;
  height: number;
  inbodyId: string;
  inbodyType: InbodyType;
};

type InbodyIndicatorsTabProps = {
  customerInbodyIndicators: InbodyIndicatorInfo[];
  mealPlansOnScanDay: MealPlanOnScanDayInfo[];
  inbodyTypeOptions: Option[];
  customerInfo: CustomerInfo;
};

const InbodyIndicatorsTab = ({
  customerInbodyIndicators,
  mealPlansOnScanDay,
  inbodyTypeOptions,
  customerInfo,
}: InbodyIndicatorsTabProps) => {
  const [isShowSocialMediaScanMetricsModal, toggleShowSocialMediaScanMetricsModal] =
    useToggle(false);
  const [isShowCompareScanMetricsModal, toggleShowCompareScanMetricsModal] =
    useToggle(false);
  const { watch, control } = useFormContext<InbodyIndicatorsFormValues>();

  const scanDateOptions = getScanDateOptions(customerInbodyIndicators);

  const selectedScanTime = watch('scanDate');

  const inbodyTypeFlags = useMemo(
    () => getFlagsObject(INBODY_TYPES, [customerInfo.inbodyType]),
    [customerInfo.inbodyType],
  );

  const tabsConfigs: TabsConfig[] = [
    {
      label: <ShowChartIcon fontSize="small" />,
      value: CHART,
      panel: (
        <InbodyIndicatorsChartView
          customerInbodyIndicators={customerInbodyIndicators}
          mealPlansOnScanDay={mealPlansOnScanDay}
          selectedScanTime={selectedScanTime}
          customerInbodyTypeFlags={inbodyTypeFlags}
        />
      ),
    },
    {
      label: <FormatListBulletedRoundedIcon fontSize="small" />,
      value: TABLE,
      panel: (
        <InbodyIndicatorsTableView
          customerInbodyIndicators={customerInbodyIndicators}
          selectedScanTime={selectedScanTime}
        />
      ),
    },
  ];

  const generalInputProps = {
    control,
    className: classes.input,
    variant: 'outlined',
    size: 'small',
  } as const;

  const hideScanSummaryButton =
    inbodyTypeFlags.NON_INBODY ||
    inbodyTypeFlags.INBODY_270 ||
    inbodyTypeFlags.INBODY_370;

  const defaultTab = inbodyTypeFlags.NON_INBODY ? TABLE : CHART;

  return (
    <>
      <LbTabs defaultValue={defaultTab}>
        <Paper variant="box" className={classes.topBlock}>
          <div className={classes.leftItems}>
            {!inbodyTypeFlags.NON_INBODY && (
              <>
                <CustomerParam
                  title="ID"
                  value={customerInfo.inbodyId}
                  className="private-info"
                />
                <Divider orientation="vertical" flexItem />
              </>
            )}
            <CustomerParam title="Height" value={`${customerInfo.height}in`} />
            <Divider orientation="vertical" flexItem />
            <CustomerParam title="Age" value={customerInfo.age} />
            <Divider orientation="vertical" flexItem />
            <CustomerParam title="Gender" value={customerInfo.gender} />
            <Divider orientation="vertical" flexItem />
            {!inbodyTypeFlags.NON_INBODY && (
              <>
                <LbSelect
                  {...generalInputProps}
                  name="inbodyType"
                  label="InBody Machine"
                  options={inbodyTypeOptions}
                />
                <Divider orientation="vertical" flexItem />
              </>
            )}
            <ComboBox
              {...generalInputProps}
              name="scanDate"
              label="Scan Date"
              options={scanDateOptions}
            />
          </div>
          <div className={classes.rightItems}>
            <LbTabsList
              className={classes.tabsList}
              tabsConfig={tabsConfigs}
              variant="toggle"
            />
            {!hideScanSummaryButton && (
              <Button
                variant="outlined"
                size="small"
                onClick={() => openScanSummary(selectedScanTime, customerInfo.id)}
              >
                Scan Summary
              </Button>
            )}
            <InbodyIndicatorsOptions
              onCompareScanMetrics={toggleShowCompareScanMetricsModal}
              onRequestSocialTemplate={toggleShowSocialMediaScanMetricsModal}
              customerInbodyTypeFlags={inbodyTypeFlags}
            />
          </div>
        </Paper>
        <LbTabPanel tabsConfig={tabsConfigs} />
      </LbTabs>
      {isShowSocialMediaScanMetricsModal && (
        <SocialMediaScanMetricsModal
          customerId={customerInfo.id}
          scanDateTimes={getScanDates(customerInbodyIndicators)}
          onClose={toggleShowSocialMediaScanMetricsModal}
        />
      )}
      {isShowCompareScanMetricsModal && (
        <CompareScanMetricsModal
          customerInfo={customerInfo}
          customerInbodyIndicators={customerInbodyIndicators}
          onClose={toggleShowCompareScanMetricsModal}
        />
      )}
    </>
  );
};

export { InbodyIndicatorsTab };
